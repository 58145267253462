import React, { useState, useEffect } from 'react';

export const doEncode = (value) => {
    let retVal = '';
    if (value) {
        retVal = value.toLowerCase().replace(/[^a-z0-9 _-]+\s+/gi, '');
    }
    return encodeURIComponent(retVal);
};

export const ShowTimeFromDate = (props) => {
    const { fromDate, isUnknown } = props;
    const [timePassed, setTimePassed] = useState('00:00');
    const time =
        (new Date(fromDate).getHours() < 10 ? '0' : '') +
        new Date(fromDate).getHours() +
        ':' +
        new Date(fromDate).getMinutes();
    useEffect(() => {
        const timer = setInterval(() => {
            setTimePassed(calculateTimeLeft(fromDate));
        }, 5000);
        return () => clearInterval(timer);
    });

    useEffect(() => {
        setTimePassed(calculateTimeLeft(fromDate));
    }, [fromDate]);

    const calculateTimeLeft = (isFrom) => {
        const isNow = new Date();
        const fDate = new Date(isFrom);

        const difference = isNow.getTime() - fDate.getTime();
        let hours = parseInt(difference / (1000 * 3600));
        const minutes = parseInt(
            (Math.abs(isNow.getTime() - fDate.getTime()) / (1000 * 60)) % 60
        );
        if (hours.toString().length > 2) hours = '0' + hours.toString();

        return `${hours}t:${('0' + minutes).slice(-2)}m`; //('0' + start.getDate()).slice(-2)
    };
    return (
        <div className='rightCorner'>
            <p className='right-float sdtime'>{isUnknown ? '' : time}</p>
            <p className={'right-float red-txt sympttime'}>
                {isUnknown ? 'Ukjent' : timePassed}
            </p>
        </div>
    );
};
