import React, { useEffect } from 'react';
import './Dropdown.scss';

const Dropdown = ({ label, options, onChange, age }) => {
    useEffect(() => {}, [age]);
    return (
        <div className='dropdown-container'>
            {label && <label className='dropdown-label'>{label}</label>}
            <select
                className='dropdown-select'
                onChange={onChange}
                value={age}
                autoComplete='off'
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;
