import React from 'react';
import './InputTextField.scss';

const InputTextField = props => {
    const { onChange, lbl } = props;

    const changeValue = e => {
        onChange(e.target.value);
    };

    return(
        <label className={`inputfield-txt${props.className ? ` ${props.className}` : ''}`}>
            <span className="chck-lbl">{lbl || ''}</span>
            <input {...props} type={props.type || 'text'} onChange={changeValue} autoComplete="off" />
        </label>
    );
};

export default InputTextField;
