export const isValidBirthNo = fodselsnr => {
    fodselsnr = fodselsnr.replace(/\s/g, '');

    if (fodselsnr.length !== 11) {
        return false;
    }

    function sum(birthNumber, factors) {
        return factors.reduce(function(sum, factor, i) {
            return sum + parseInt(birthNumber.charAt(i), 10) * factor;
        }, 0);
    }

    const arr1 = [3, 7, 6, 1, 8, 9, 4, 5, 2, 1];
    const arr2 = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
    const chk1 = sum(fodselsnr, arr1);
    const chk2 = sum(fodselsnr, arr2);

    const mod1 = chk1 % 11;
    const mod2 = chk2 % 11;

    return mod1 === 0 && mod2 === 0;
};
function Prescription(lbl, name) {
    this.lbl = lbl;
    this.name = name;
};
export const legemidler = [
    new Prescription('Ingen', 'None'),
    new Prescription('Ukjent', 'Unknown'),
    new Prescription('Apixaban (Eliquis)', 'A1'),
    new Prescription('Dabigatran (Pradaxa)', 'D1'),
    new Prescription('Edoxaban (Lixiana)', 'E1'),
    new Prescription('Rivaroxaban (Xarelto)', 'R1'),
    new Prescription('Warfarin (Marevan)', 'W1'),
    new Prescription('Acetylsalisylsyre (Albyl-E)', 'A2'),
    new Prescription('Clopidogrel (Plavix)', 'C1'),
    new Prescription('Dipyridamol (Asasantin/Persantin)', 'D2'),
    new Prescription('Heparin (Fragmin)', 'H1'),
    new Prescription('Øvrig relevant (Legg til kommentar)', 'Others')
];
