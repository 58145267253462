import React from 'react';
import InputTextField from '../InputTextField/InputTextField';
import InputSwitch from '../InputSwitch/InputSwitch';
import { isValidBirthNo } from '../variables/inputVariables';

const IdPerson = (props) => {
    const { caseInfo, changeCaseInfo, toNext } = props;
    const { birthNo, unknownBirthNo, aId } = caseInfo;

    const changeCase = (val, field) => {
        let updatedInfo = { ...caseInfo };
        updatedInfo[field] = val;
        changeCaseInfo(updatedInfo);
    };
    const setBirthno = (val) => changeCase(val, 'birthNo');
    const setcheck = (val) => changeCase(val, 'unknownBirthNo');
    const setAId = (val) => changeCase(val, 'aId');

    const onSubmit = (e) => {
        let continueNext = true;
        e.preventDefault();
        if (unknownBirthNo) {
            if (!aId || aId.length < 1) continueNext = false;
        } else {
            const isValdidInput = isValidBirthNo(birthNo);
            if (isValdidInput) {
                /*startNewStroke({...sendObj, fnr: birthNo}).then(response => {
                    if (response && response.status === 0) { //if ok
                        let updatedInfo = {...caseInfo};
                        updatedInfo['CaseId'] = response.data.CaseId;
                        updatedInfo['person_info'] = response.data.person_info;

                        changeCaseInfo(updatedInfo);
                        toNext('/symptomdebut');
                    }
                });*/
            }
        }
        if (continueNext) {
            changeCase(true, 'validOk');
            toNext('/symptomdebut');
        }
    };
    return (
        <div className='IdPerson'>
            <h1>Person ID</h1>

            <InputTextField
                value={birthNo}
                onChange={setBirthno}
                lbl='Fødselsnr (11 siffer)'
                name='Birthno'
                pattern='[0-9]{11}'
                autoComplete='off'
                disabled={unknownBirthNo}
            />
            <InputSwitch
                checked={unknownBirthNo}
                setChecked={setcheck}
                lbl='Fødselsnummer er ukjent'
                name='birthnounknown'
            />

            <p>Brukes hos enkelte HF. Gir ingen mening her! :=)</p>

            {unknownBirthNo && (
                <InputTextField
                    value={aId}
                    onChange={setAId}
                    autoComplete='off'
                    lbl='Amis nummer'
                    name='unidentifiedalias'
                />
            )}
            <button type='button' onClick={onSubmit} className='next-btn'>
                <span>Neste</span>
            </button>
        </div>
    );
};

export default IdPerson;
