import React from 'react';
import './InputMultipleSelect.scss';

const CheckBoxMultiple = props => {
    const {checked, changeCheck, name, lbl, disabled} = props;

    const updateCheck = e => {
        changeCheck(e.target.checked, name);
    }
    return(
        <label className={`mutiple-check-lbl${checked ? ' is-checked' : ''}${disabled ? ' is-disabled' : ''}`}>
            <input type="checkbox" name={name} checked={checked} onChange={updateCheck} disabled={disabled} />
            <span className="chck-lbl">{lbl || ''}</span>
        </label>
    );
};

const InputMultipleSelect = props => {
    const {areaName, showAreaName, options, changeOptions} = props;

    const changeFromOption = (newVal, itmName) => {
        const newOptions = [...options];
        newOptions.forEach((obj, index) => {
            if (obj.name === itmName) {
                newOptions[index] = {...newOptions[index], checked: newVal}
            }
        })
        changeOptions(newOptions);
    }
    return(
        <fieldset className="InputMultipleSelect">
            <legend className={showAreaName ? 'areaname-show' : 'areaname-hidden'}>{areaName}</legend>
            {options.map(option => {
                return(<CheckBoxMultiple key={option.name} {...option} changeCheck={changeFromOption} />)
            })}
        </fieldset>
    );
};

export default InputMultipleSelect;
