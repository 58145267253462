import React from 'react';
import './VitaliaErrorMsg.scss';

const VitaliaErrorMsg = ({ show, min, max }) => {
    if (show === null || show === true) {
        return null;
    }

    return (
        <span className='error-msg'>
            {min === max
                ? 'Verdi må være 0'
                : `Verdi må være mellom ${min}-${max}`}
        </span>
    );
};

export default VitaliaErrorMsg;
