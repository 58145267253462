import React from 'react';
import './InputSwitch.scss';

const InputSwitch = props => {
    const { checked, setChecked, lbl, name } = props;

    const changeCheck = e => {
        setChecked(e.target.checked);
    };

    return(
        <label className={`slider-check-lbl${checked ? ' is-checked' : ''}`}>
            <input type="checkbox" name={name} checked={checked} onChange={changeCheck} />
            <span className="chck-lbl">{lbl || ''}</span>
            <div className="slider-box"></div>
        </label>
    );
};

export default InputSwitch;
