import { doEncode } from '../generalFunctions';
function NihssStep(urlName, title, task, NIHSSref, refId, answerOpts) {
    this.urlName = urlName;
    this.title = title;
    this.task = task;
    this.NIHSSref = NIHSSref;
    this.refId = refId;
    this.answerOpts = answerOpts;
}
function OptionObj(title, img, score, width) {
    this.title = title;
    if (img instanceof Object) {
        this.imgs = img;
    } else {
        this.img = img;
    }
    this.score = score;
    this.width = width;
}

export const stepObjName = (stepObj) =>
    `${stepObj.NIHSSref || ''}-${stepObj.refId || '1'}`;
export const stepUrl = (stepObj) => `/${doEncode(stepObjName(stepObj))}`;

const bevistthetOpts = [];
bevistthetOpts.push(new OptionObj('Våken', 'Blikkbevegelse-2-1-0', 0, 50));
bevistthetOpts.push(
    new OptionObj(
        'Døsig, reagerer ved lett stimulering',
        'Bevissthet-1a-1',
        1,
        50
    )
);
bevistthetOpts.push(
    new OptionObj(
        'Døsig, reagerer først ved kraftigere/gjentatt stimulering',
        'Bevissthet-1a-2',
        2,
        50
    )
);
bevistthetOpts.push(
    new OptionObj(
        'Reagerer ikke, eller bare med ikke-målrettet bevegelse',
        'Bevissthet-1a-3',
        3,
        50
    )
);
export const stepBevissthet = new NihssStep(
    'Bevissthet',
    'Bevissthet',
    'Kontroller bevissthet',
    '1a',
    1,
    bevistthetOpts
);

const optsAge = [];
optsAge.push(
    new OptionObj('Svaret er riktig', 'Orientering-alder-1b-1-1', 0, 50)
);
optsAge.push(
    new OptionObj('Svaret er galt', 'Orientering-alder-1b-1-0', 1, 50)
);
export const stepAge = new NihssStep(
    'OrienteringAlder',
    'Orientering - Alder',
    'Spør om alder',
    '1b',
    1,
    optsAge
);

const monthOpts = [];
monthOpts.push(
    new OptionObj('Svaret er riktig', 'Orientering-maaned-1b-2-0', 0, 50)
);
monthOpts.push(
    new OptionObj('Svaret er galt', 'Orientering-maaned-1b-2-1', 1, 50)
);
export const stepMonth = new NihssStep(
    'Orienteringmaaned',
    'Orientering - Måned',
    'Spør om måned',
    '1b',
    2,
    monthOpts
);

const sprakOpts = [];
sprakOpts.push(new OptionObj('Normalt', 'Spraak-9-0', 0, 50));
sprakOpts.push(
    new OptionObj(
        'Samtale mulig, men har problem med A, B eller C',
        'Spraak-9-1',
        1,
        50
    )
);
sprakOpts.push(
    new OptionObj(
        'Samtale svært vanskelig/umulig, pga A, B eller C',
        'Spraak-9-2',
        2,
        50
    )
);
sprakOpts.push(new OptionObj('Ikke språk/komatøs', 'Spraak-9-3', 3, 50));
export const stepSpråk = new NihssStep(
    'Sprak',
    'Språk',
    'Vis fram lykten din. A.hva er dette?, B.farge?, C.hva brukes den til?',
    '9',
    1,
    sprakOpts
);

const taleOpts = [];
taleOpts.push(new OptionObj('Normalt', 'Tale-10-0', 0, 50));
taleOpts.push(new OptionObj('Lett-moderat snøvlete tale', 'Tale-10-1', 1, 50));
taleOpts.push(new OptionObj('Svært snøvlete tale/komatøs', 'Tale-10-2', 2, 50));
export const stepTale = new NihssStep('tale', 'Tale', '', '10', 1, taleOpts);

const kommandoOpts = [];
kommandoOpts.push(
    new OptionObj('Utfører begge kommandoer korrekt', 'Kommando-1c-1-0', 0, 50)
);
kommandoOpts.push(
    new OptionObj(
        'Utfører kun lukking av øyne korrekt',
        'Kommando-1c-1-1',
        1,
        50
    )
);
kommandoOpts.push(
    new OptionObj(
        'Utfører kun knytting av hånd korrekt',
        'Kommando-1c-1-2',
        1,
        50
    )
);
kommandoOpts.push(
    new OptionObj('Utfører ingen korrekt/komatøs', 'Kommando-1c-1-3', 2, 50)
);
export const stepKommando = new NihssStep(
    'kommando',
    'Kommando',
    'Be pasienten lukke øynene + knytte hånd',
    '1c',
    1,
    kommandoOpts
);

const blikkBevOpts = [];
blikkBevOpts.push(new OptionObj('Normal', 'Blikkbevegelse-2-1-0', 0, 100));
blikkBevOpts.push(
    new OptionObj(
        'Delvis innskrenket blikkbevegelse',
        { left: 'Blikkbevegelse-2-1-1', right: 'Blikkbevegelse-2-1-2' },
        1,
        100
    )
);
blikkBevOpts.push(
    new OptionObj(
        'Fiksert blikkposisjon (øynene står hele tiden i samme posisjon)',
        { left: 'Blikkbevegelse-2-1-3', right: 'Blikkbevegelse-2-1-4' },
        2,
        100
    )
);
export const stepBlikkBevegelse = new NihssStep(
    'blikkbevegelse',
    'Blikkbevegelse',
    'Bevege penn/lykt i horisontalplanet, vurdere øyebevegelse',
    '2',
    1,
    blikkBevOpts
);

const synsfeltOpts = [];
synsfeltOpts.push(new OptionObj('Normal', 'Blikkbevegelse-2-1-0', 0, 100));
synsfeltOpts.push(
    new OptionObj(
        'Ser delvis ikke til den ene siden',
        { left: 'Synsfelt-3-1-2', right: 'Synsfelt-3-1-1' },
        1,
        100
    )
);
synsfeltOpts.push(
    new OptionObj(
        'Ser ikke noe til den ene siden',
        { left: 'Synsfelt-3-1-4', right: 'Synsfelt-3-1-3' },
        2,
        100
    )
);
synsfeltOpts.push(
    new OptionObj(
        'Pasienten ser ikke på noen sider/blind begge øyne',
        'Synsfelt-3-1-5',
        3,
        100
    )
);
export const stepSynsfelt = new NihssStep(
    'synsfelt',
    'Synsfelt',
    'Test ytre del av synsfelt på begge sider samtidig. Test en og en side hvis unormalt. Hvis synsfeltet fortsatt er unormalt, skåres her.',
    '3',
    1,
    synsfeltOpts
);

const syneglectOpts = [];
syneglectOpts.push(new OptionObj('Normal', 'Neglekt-11-1-0', 0, 100));
syneglectOpts.push(
    new OptionObj(
        'Nedsatt oppmerksomhet til en side eller koma',
        { left: 'Neglekt-11-1-2', right: 'Neglekt-11-1-1' },
        1,
        100
    )
);
export const stepSynsfeltNeglect = new NihssStep(
    'synsfeltoppmerksomhet',
    'Neglekt/uoppmerksomhet for synsfelt',
    'Hvis synet er tilbake på begge sider ved test av hver side for seg gis poeng for neglekt.',
    '11',
    1,
    syneglectOpts
);

const ansiktOpts = [];
ansiktOpts.push(new OptionObj('Normal', 'Ansikt-4-1-0', 0, 100));
ansiktOpts.push(
    new OptionObj(
        'Lett hengende munnvik',
        { left: 'Ansikt-4-1-2', right: 'Ansikt-4-1-1' },
        1,
        100
    )
);
ansiktOpts.push(
    new OptionObj(
        'Tydelig hengende munnvik',
        { left: 'Ansikt-4-1-4', right: 'Ansikt-4-1-3' },
        2,
        100
    )
);
ansiktOpts.push(
    new OptionObj(
        'Lammelse i halve ansiktet',
        { left: 'Ansikt-4-1-6', right: 'Ansikt-4-1-5' },
        3,
        100
    )
);
export const stepAnsikt = new NihssStep(
    'ansikt',
    'Ansikt',
    'Vise tenner, knipe igjen øynene, løfte øyenbryn',
    '4',
    1,
    ansiktOpts
);

const armLeftOpts = [];
armLeftOpts.push(new OptionObj('Normal', 'Kraft-i-arm-5-1-0', 0, 100));
armLeftOpts.push(
    new OptionObj('Drifter til lavere posisjon', 'Kraft-i-arm-5-1-1', 1, 100)
);
armLeftOpts.push(
    new OptionObj(
        'Bevegelse mot tyngdekraften, men drifter helt ned',
        'Kraft-i-arm-5-1-2',
        2,
        100
    )
);
armLeftOpts.push(
    new OptionObj(
        'Sees muskelbevegelse, men løfter ikke opp fra underlaget',
        'Kraft-i-arm-5-1-3',
        3,
        100
    )
);
armLeftOpts.push(
    new OptionObj('Ingen bevegelse/komatøs', 'Kraft-i-arm-5-1-4', 4, 100)
);
export const stepArmLeft = new NihssStep(
    'kraftiarmvenstre',
    'Kraft i arm (venstre)',
    '',
    '5',
    1,
    armLeftOpts
);

const armRightOpts = [];
armRightOpts.push(new OptionObj('Normal', 'Kraft-i-arm-5-2-0', 0, 100));
armRightOpts.push(
    new OptionObj('Drifter til lavere posisjon', 'Kraft-i-arm-5-2-1', 1, 100)
);
armRightOpts.push(
    new OptionObj(
        'Bevegelse mot tyngdekraften, men drifter helt ned',
        'Kraft-i-arm-5-2-2',
        2,
        100
    )
);
armRightOpts.push(
    new OptionObj(
        'Sees muskelbevegelse, men løfter ikke opp fra underlaget',
        'Kraft-i-arm-5-2-3',
        3,
        100
    )
);
armRightOpts.push(
    new OptionObj('Ingen bevegelse/komatøs', 'Kraft-i-arm-5-2-4', 4, 100)
);
export const stepArmRight = new NihssStep(
    'kraftiarmhoyre',
    'Kraft i arm (høyre)',
    '',
    '5',
    2,
    armRightOpts
);

const footLeftOpts = [];
footLeftOpts.push(new OptionObj('Normal', 'Kraft-i-bein-6-1-0', 0, 100));
footLeftOpts.push(
    new OptionObj('Drifter til lavere posisjon', 'Kraft-i-bein-6-1-1', 1, 100)
);
footLeftOpts.push(
    new OptionObj(
        'Bevegelse mot tyngdekraften, men drifter helt ned',
        'Kraft-i-bein-6-1-2',
        2,
        100
    )
);
footLeftOpts.push(
    new OptionObj(
        'Muskelbevegelser, men løfter ikke opp fra underlaget',
        'Kraft-i-bein-6-1-3',
        3,
        100
    )
);
footLeftOpts.push(
    new OptionObj('Ingen bevegelse/komatøs', 'Kraft-i-bein-6-1-4', 4, 100)
);
export const stepFootLeft = new NihssStep(
    'kraftibenvenstre',
    'Kraft i ben (venstre)',
    '',
    '6',
    1,
    footLeftOpts
);

const footRightOpts = [];
footRightOpts.push(new OptionObj('Normal', 'Kraft-i-bein-6-2-0', 0, 100));
footRightOpts.push(
    new OptionObj('Drifter til lavere posisjon', 'Kraft-i-bein-6-2-1', 1, 100)
);
footRightOpts.push(
    new OptionObj(
        'Bevegelse mot tyngdekraften, men drifter helt ned',
        'Kraft-i-bein-6-2-2',
        2,
        100
    )
);
footRightOpts.push(
    new OptionObj(
        'Muskelbevegelser, men løfter ikke opp fra underlaget',
        'Kraft-i-bein-6-2-3',
        3,
        100
    )
);
footRightOpts.push(
    new OptionObj('Ingen bevegelse/komatøs', 'Kraft-i-bein-6-2-4', 4, 100)
);
export const stepFootRight = new NihssStep(
    'kraftibenhoyre',
    'Kraft i ben (høyre)',
    '',
    '6',
    2,
    footRightOpts
);

const feelArmOpts = [];
feelArmOpts.push(
    new OptionObj('Normal begge sider', 'Skinfeeling-arm-8-1-0', 0, 100)
);
feelArmOpts.push(
    new OptionObj(
        'Nedsatt følelse i arm',
        { left: 'Skinfeeling-arm-8-1-2', right: 'Skinfeeling-arm-8-1-1' },
        1,
        100
    )
);
export const stepFeelArm = new NihssStep(
    'hudfolelsearm',
    'Hudfølelse - arm',
    'Test følelse for berøring på begge sider samtidig. Test en og en side hvis unormalt (borte eller svekket). Hvis berøringssans fortsatt er unormal, skåres her.',
    '8',
    1,
    feelArmOpts
);

const feelLegOpts = [];
feelLegOpts.push(new OptionObj('Normal', 'Skinfeeling-bein-8-2-0', 0, 100));
feelLegOpts.push(
    new OptionObj(
        'Nedsatt følelse i ben',
        { left: 'Skinfeeling-bein-8-2-2', right: 'Skinfeeling-bein-8-2-1' },
        1,
        100
    )
);
export const stepFeelLeg = new NihssStep(
    'hudfolelseben',
    'Hudfølelse - ben',
    'Test følelse for berøring på begge sider samtidig. Test en og en side hvis unormalt. Hvis berøringssans fortsatt er unormal, skåres her.',
    '8',
    2,
    feelLegOpts
);

const neglectArmOpts = [];
neglectArmOpts.push(new OptionObj('Normal', 'Neglekt-11-2-0', 0, 100));
neglectArmOpts.push(
    new OptionObj(
        'Nedsatt oppmerksomhet mot en side eller koma',
        { left: 'Neglekt-11-2-2', right: 'Neglekt-11-2-1' },
        1,
        100
    )
);
export const stepNeglectArm = new NihssStep(
    'folelseoppmerksomhet',
    'Neglekt/uoppmerksomhet for berøring',
    'Hvis berøringssansen er tilbake på begge sider ved test av hver side for seg gis poeng for neglekt.',
    '11',
    2,
    neglectArmOpts
);

const ataksiTopOpts = [];
ataksiTopOpts.push(new OptionObj('Normal', 'Koordinasjon-7-1-0', 0, 100));
ataksiTopOpts.push(
    new OptionObj(
        'Hakkete bevegelse',
        { left: 'Koordinasjon-7-1-2', right: 'Koordinasjon-7-1-1' },
        1,
        100
    )
);
export const stepAtaksiTop = new NihssStep(
    'ataksiarms',
    'Koordinasjon (Ataksi) /hakkete bevegelse',
    'Finger-/nesetest.',
    '7',
    1,
    ataksiTopOpts
);

const ataksiLegOpts = [];
ataksiLegOpts.push(new OptionObj('Normal', 'Koordinasjon-7-2-0', 0, 100));
ataksiLegOpts.push(
    new OptionObj(
        'Hakkete bevegelse',
        { left: 'Koordinasjon-7-2-2', right: 'Koordinasjon-7-2-1' },
        1,
        100
    )
);
export const stepAtaksiLeg = new NihssStep(
    'ataksileg',
    'Koordinasjon (Ataksi) /hakkete bevegelse - bein',
    'Hæl-/knetest.',
    '7',
    2,
    ataksiLegOpts
);

export const stepArr = [
    stepBevissthet,
    stepAge,
    stepMonth,
    stepSpråk,
    stepTale,
    stepKommando,
    stepBlikkBevegelse,
    stepSynsfelt,
    stepSynsfeltNeglect,
    stepAnsikt,
    stepArmLeft,
    stepArmRight,
    stepFootLeft,
    stepFootRight,
    stepFeelArm,
    stepFeelLeg,
    stepNeglectArm,
    stepAtaksiTop,
    stepAtaksiLeg
];
