import React from 'react';
import './SymptomDebut.scss';
import InputSwitch from '../InputSwitch/InputSwitch';
import { InputFullDate } from '../InputDateFields/InputDateFields';

const SymptomDebut = (props) => {
    const { nextUrl, vitalia, setVitalia, loadFields, goToPage } = props;

    const setDebutTime = val => {
        setVitalia('symptomDebut', val);
    };
    const setCheckedUnknown = val => {
        setVitalia('unknownDebut', val);
    };
    const validatePage = () => {
        setVitalia('sbValidated', true);
        goToPage(nextUrl);
    };

    return(
        <div className="symptom-debut">
            <h1>Symptomdebut</h1>
            <p>Tidspunkt for symptomdebut</p>
            {(vitalia && vitalia.symptomDebut && loadFields) &&
                <InputFullDate
                    inDate={vitalia.symptomDebut}
                    changeDate={setDebutTime}
                    disabled={vitalia.unknownDebut}
                />
            }
            {vitalia &&
                <InputSwitch
                    checked={vitalia.unknownDebut}
                    setChecked={setCheckedUnknown}
                    lbl="SYMPTOMDEBUT er ukjent"
                    name="symptomhasdebut"
                />
            }
            <button onClick={validatePage} className="next-btn">
                <span>NESTE</span>
            </button>
        </div>
    )
}

export default SymptomDebut;
