import React, {useEffect} from 'react';
import {dateVal, getDate, getMonthTxt, getWeekDay, months} from "../variables/dateVariables";
import './InputDateFields.scss';

export const InputFullDate = props => {
    const {inDate, changeDate, disabled, legend} = props;

    return(
        <div className="time-selects">
            {legend && <p className="legend-txt">{legend}</p>}
            <DaySelect value={inDate} setValue={changeDate} isDisabled={disabled}/>
            <div className="hours-wrapper">
                <HourSelect value={inDate} setValue={changeDate} isDisabled={disabled}/>
                <MinuteSelect value={inDate} setValue={changeDate} isDisabled={disabled}/>
            </div>
        </div>
    )
};

export const DaySelect = props => {
    const {value, setValue, isDisabled} = props;
    const options = [];
    let now = new Date();
    let nowVal = dateVal(now);
    let tDate = new Date();
    tDate.setHours(tDate.getHours() - 96);

    while (tDate <= now) {
        let dYr = tDate.getFullYear();
        let dMnth = getMonthTxt(tDate);
        let dDay = getDate(tDate);
        let wDay = getWeekDay(tDate);
        options.push({val: dateVal(tDate) , year: dYr, month: dMnth, day: dDay, weekDay: wDay });
        tDate.setHours(tDate.getHours() + 24);
    };

    const changeVal = e => {
        let nDate = new Date(e.target.value);
        nDate.setHours(value.getHours());
        nDate.setMinutes(value.getMinutes());
        setValue(nDate);
    };
    return(
        <label className={`select-lbl`}>
            <span>DAG:</span>
            <select value={dateVal(value)} onChange={changeVal} disabled={isDisabled}>
                {options.map(opt => {
                    return(
                        <option key={opt.val}
                                value={opt.val}
                        >
                            {nowVal === opt.val ?
                                `I dag (${opt.day}. ${months[parseInt(opt.month) - 1]})`
                                :
                                `${opt.weekDay}. ${opt.day}. ${months[parseInt(opt.month) - 1]}`
                            }
                        </option>
                    );
                })}
            </select>
        </label>
    );

};
export const HourSelect = props => {
    const {value, setValue, isDisabled} = props;
    const options = Array.from({length: 24}, (_, i) => i);

    const changeVal = e => {
        if (value instanceof Date) {
            let nDate = new Date(value);
            nDate.setHours(e.target.value);
            setValue(nDate);
        }
    };
    return(
        <label className={`select-lbl select-hours${isDisabled ? ' is-disabled' : ''}`}>
            <span>HH:</span>
            <select name="symptom-hours" value={value.getHours()} onChange={changeVal} disabled={isDisabled}>
                {options.map(opt => {
                    return(<option key={opt} value={opt}>{('0' + opt).slice(-2)}</option>);
                })}
            </select>
        </label>
    );
};
export const MinuteSelect = props => {
    const {value, setValue, isDisabled} = props;

    useEffect(() => {
        if (value instanceof Date && !isNaN(value)) {
            if (value && value.getMinutes() % 5 !== 0) {
                let nDate = new Date(value);
                nDate.setMinutes((Math.ceil(value.getMinutes() / 5) * 5) - 5);
                nDate.setMinutes((Math.ceil(value.getMinutes() / 5) * 5) - 5);
                setValue(nDate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const options = [];
    let i = 0;
    while (i < 61) {
        options.push(i);
        i = i + 5;
    }

    const changeVal = e => {
        let nDate = new Date(value);
        nDate.setMinutes(e.target.value);
        setValue(nDate);
    };
    return(
        <label className={`select-lbl select-minutes${isDisabled ? ' is-disabled' : ''}`}>
            <span>MM:</span>
            <select name="symptom-hours" value={value.getMinutes()} onChange={changeVal} disabled={isDisabled}>
                {options.map(opt => {
                    return(<option key={opt} value={opt}>{('0' + opt).slice(-2)}</option>);
                })}
            </select>
        </label>
    );
};
