export const weekdays = ['man', 'tir', 'ons', 'tor', 'fre', 'lør', 'søn'];
export const months = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
export const getMonthTxt = inDate => ('0' + (inDate.getMonth() + 1).toString()).slice(-2);
export const getHoursTxt = inDate => ('0' + (inDate.getHours()).toString()).slice(-2);
export const getMinsTxt = inDate => ('0' + (inDate.getMinutes()).toString()).slice(-2);
export const getSecondsTxt = inDate => ('0' + (inDate.getSeconds()).toString()).slice(-2);
export const getDate = inDate => ('0' + inDate.getDate().toString()).slice(-2);
export const getWeekDay = inDate => weekdays[(inDate.getDay() || 0)];
export const dateVal = inDate => {
    return(
        `${inDate.getFullYear()}/${getMonthTxt(inDate)}/${getDate(inDate)}`
    );
}
export const dateValWithhours = inDate => {
    return(
        `${inDate.getFullYear()}/${getMonthTxt(inDate)}/${getDate(inDate)} ${getHoursTxt(inDate)}:${getMinsTxt(inDate)}`
    );
}
export const dateValForApi = inDate => {
    return(
        `${inDate.getFullYear()}-${getMonthTxt(inDate)}-${getDate(inDate)} ${getHoursTxt(inDate)}:${getMinsTxt(inDate)}:${getSecondsTxt(inDate)}`
    );
}

export const dateValHrsView = inDate => {
    return(
        `${getDate(inDate)}.${getMonthTxt(inDate)} ${getHoursTxt(inDate)}:${getMinsTxt(inDate)}`
    );
}
