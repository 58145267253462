import React, { useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Header from './Header/Header';
import StartPage from './StartPage/StartPage';
import NyPasient from './NyPasient/NyPasient';
import IdPerson from './IdPerson/IdPerson';
import SymptomDebut from './SymptomDebut/SymptomDebut';
import LegemidlerPage from './LegemidlerPage/LegemidlerPage';
import Pasient from './Pasient/Pasient';
import VitaliaPage from './VitaliaPage/VitaliaPage';
import NihssStep from './NihssStep/NihssStep';
import Summary from './Summary/Summary';
import { doEncode } from './generalFunctions';
import { dateValWithhours } from './variables/dateVariables';
import { stepArr, stepObjName } from './variables/generalVariables';
import { legemidler } from './variables/inputVariables';
import {
    setVitaliaS,
    getVitaliaS,
    setScoreS,
    getScoreS,
    getMedicineS,
    setMedicineS,
    getCaseInfoS,
    setCaseInfoS,
    getGender,
    setGenderS,
    getAge,
    setAgeS,
    setVitaliaBS,
    setVitaliaTemp,
    setVitaliaPulse,
    getBloodSugar,
    getTemp,
    getPulse,
    getVersionS,
    setVersionS
} from './variables/sessionVariables';
import './App.scss';

function App() {
    let history = useHistory();
    const [version, setVersion] = useState('');
    const [scoreBoard, setScoreBoard] = useState({});
    const [medicine, setMedicine] = useState({
        meds: [],
        inntakUnknown: false,
        inntakTime: new Date(),
        hasNote: false,
        note: ''
    });
    const [vitalia, setVitalia] = useState({
        symptomDebut: new Date(),
        lbl: 'Blodtrykk ukjent.',
        unknownDebut: false,
        sbValidated: false,
        bpSystol: 120,
        bpDiast: 80,
        known: false
    });
    const [loadVitaliaFields, setLoadVitaliaFields] = useState(false);
    const [startCaseInfo, setStartCaseInfo] = useState({
        birthNo: '',
        unknownBirthNo: false,
        aId: '',
        startDateTime: null,
        validOk: false
    });
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('60 år');
    const [bloodSugar, setBloodSugar] = useState({
        lbl: 'Blodsukker ukjent.',
        left: 5,
        right: 0,
        known: false
    });
    const [temp, setTemp] = useState({
        lbl: 'Temperatur ukjent.',
        left: 37,
        right: 0,
        known: false
    });
    const [pulse, setPulse] = useState({
        lbl: 'Puls ukjent.',
        value: 60,
        known: false
    });

    const pushToHistory = (val) => {
        history.push(val);
    };
    useEffect(() => {
        let version = getVersionS();
        if (version) {
            updateVersion(JSON.parse(version));
        } else {
            updateVersion(null);
        }

        let hasScoreBoard = getScoreS();
        if (hasScoreBoard) {
            setScoreBoard(JSON.parse(hasScoreBoard));
        }
        let hasVitalia = getVitaliaS();
        if (hasVitalia) {
            let jsonVitalia = JSON.parse(hasVitalia);
            let newDebut = new Date(jsonVitalia.symptomDebut);
            jsonVitalia = {
                ...vitalia,
                ...jsonVitalia,
                symptomDebut: newDebut
            };
            setVitalia(jsonVitalia);
        }
        setLoadVitaliaFields(true);
        let hasMedicine = getMedicineS();
        if (hasMedicine) {
            let jsonMedicine = JSON.parse(hasMedicine);
            let newInntakTime = new Date(jsonMedicine.inntakTime);
            jsonMedicine = {
                ...medicine,
                ...jsonMedicine,
                inntakTime: newInntakTime
            };
            setMedicine(jsonMedicine);
        }
        let hasCaseInfo = getCaseInfoS();
        if (hasCaseInfo) {
            let jParse = JSON.parse(hasCaseInfo);
            let newStartTime = new Date(jParse.startDateTime);
            jParse = {
                ...startCaseInfo,
                ...jParse,
                startDateTime: newStartTime
            };
            setStartCaseInfo(jParse);
        }
        let hasGender = getGender();
        if (hasGender) {
            setGender(JSON.parse(hasGender));
        }
        let hasAge = getAge();
        if (hasAge) {
            setAge(JSON.parse(hasAge));
        }
        let hasBS = getBloodSugar();
        if (hasBS) {
            setBloodSugar(JSON.parse(hasBS));
        }
        let hasTemp = getTemp();
        if (hasTemp) {
            setTemp(JSON.parse(hasTemp));
        }
        let hasPulse = getPulse();
        if (hasPulse) {
            setPulse(JSON.parse(hasPulse));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateVersion = (content) => {
        setVersion(content);
        setVersionS(content);
    };

    const updateScore = (itm, score) => {
        let updatedScore = { ...scoreBoard };
        updatedScore[itm] = score;
        setScoreBoard(updatedScore);
        setScoreS(updatedScore);
    };
    const updateVitalia = (itm, update) => {
        let updatedVitalia = { ...vitalia };
        updatedVitalia[itm] = update;
        setVitalia(updatedVitalia);
        updatedVitalia = {
            ...updatedVitalia,
            symptomDebut: dateValWithhours(updatedVitalia.symptomDebut)
        };
        setVitaliaS(updatedVitalia);
    };
    const updateMedicine = (content) => {
        setMedicine(content);
        setMedicineS(content);
    };
    const updateCaseInfo = (content) => {
        setStartCaseInfo(content);
        setCaseInfoS(content);
    };
    const updateGender = (content) => {
        setGender(content);
        setGenderS(content);
    };

    const updateAge = (content) => {
        setAge(content);
        setAgeS(content);
    };
    const updateBloodSugar = (itm, update) => {
        let updatedBS = { ...bloodSugar };
        updatedBS[itm] = update;
        setBloodSugar(updatedBS);
        setVitaliaBS(updatedBS);
    };

    const updateTemp = (itm, update) => {
        let updatedTemp = { ...temp };
        updatedTemp[itm] = update;
        setTemp(updatedTemp);
        setVitaliaTemp(updatedTemp);
    };

    const updatePulse = (itm, update) => {
        let updatedPulse = { ...pulse };
        updatedPulse[itm] = update;
        setPulse(updatedPulse);
        setVitaliaPulse(updatedPulse);
    };
    return (
        <div className='App'>
            <Header
                history={history}
                goToPage={pushToHistory}
                scoreBoard={scoreBoard}
                startCaseInfo={startCaseInfo}
                vitalia={vitalia}
                version={version}
            />
            <main>
                <Switch>
                    <Route
                        path='/'
                        render={() => (
                            <StartPage
                                toNext={() => pushToHistory('/nypasient')}
                                updateVersion={updateVersion}
                            />
                        )}
                        exact
                    />
                    <Route
                        path='/nypasient'
                        render={() => (
                            <NyPasient
                                toNext={() =>
                                    pushToHistory(
                                        version === 'mobile'
                                            ? '/symptomdebut'
                                            : '/identifiser'
                                    )
                                }
                                caseInfo={startCaseInfo}
                                changeCaseInfo={updateCaseInfo}
                            />
                        )}
                        exact
                    />
                    <Route
                        path='/identifiser'
                        render={() => (
                            <IdPerson
                                caseInfo={startCaseInfo}
                                changeCaseInfo={updateCaseInfo}
                                toNext={pushToHistory}
                            />
                        )}
                    />
                    <Route
                        path='/symptomdebut'
                        render={() => (
                            <SymptomDebut
                                nextUrl={'/medikamenter'}
                                loadFields={loadVitaliaFields}
                                vitalia={vitalia}
                                setVitalia={updateVitalia}
                                goToPage={pushToHistory}
                            />
                        )}
                    />
                    <Route
                        path='/medikamenter'
                        render={() => (
                            <LegemidlerPage
                                medicine={medicine}
                                legemidler={legemidler}
                                changeLegemidler={updateMedicine}
                                toNext={() => pushToHistory('/pasient')}
                            />
                        )}
                    />
                    <Route
                        path='/pasient'
                        render={() => (
                            <Pasient
                                gender={gender}
                                setGender={updateGender}
                                toNext={() =>
                                    pushToHistory(
                                        `/${doEncode(
                                            stepObjName(stepArr[0] || '')
                                        )}`
                                    )
                                }
                            />
                        )}
                    />
                    {stepArr.map((step, ind) => {
                        return (
                            <Route
                                key={ind}
                                path={`/${doEncode(stepObjName(step))}`}
                                render={() => (
                                    <NihssStep
                                        stepObj={step}
                                        prevObj={
                                            ind === 0
                                                ? { urlName: 'symptomdebut' }
                                                : stepArr[ind - 1]
                                        }
                                        nextObj={stepArr[ind + 1]}
                                        updateScore={updateScore}
                                        goToPage={pushToHistory}
                                        age={age}
                                        setAge={updateAge}
                                    />
                                )}
                            />
                        );
                    })}
                    <Route
                        path='/vitalia'
                        render={() => (
                            <VitaliaPage
                                vitalia={vitalia}
                                setVitalia={updateVitalia}
                                toNext={() => pushToHistory('/oppsummering')}
                                bloodSugar={bloodSugar}
                                setBloodSugar={updateBloodSugar}
                                temp={temp}
                                setTemp={updateTemp}
                                pulse={pulse}
                                setPulse={updatePulse}
                            />
                        )}
                    />
                    <Route
                        path='/oppsummering'
                        render={() => (
                            <Summary
                                version={version}
                                scoreBoard={scoreBoard}
                                vitalia={vitalia}
                                caseInfo={startCaseInfo}
                                medicine={medicine}
                                gender={gender}
                                age={age}
                                bloodSugar={bloodSugar}
                                temp={temp}
                                pulse={pulse}
                            />
                        )}
                    />
                </Switch>
            </main>
        </div>
    );
}

export default App;
