import React from 'react';
import './NyPasient.scss';

const NyPasient = (props) => {
    const { toNext, caseInfo, changeCaseInfo } = props;

    const onChange = () => {
        changeCaseInfo({ ...caseInfo, startDateTime: new Date() });
        toNext();
    };

    return (
        <div className='NyPasient'>
            <h1>eStroke</h1>
            <button className='start-new-test' onClick={onChange}>
                <img src='images/btn_circle_doctortraining_down.png' alt='' />
                <span>Ny pasient</span>
            </button>
        </div>
    );
};

export default NyPasient;
