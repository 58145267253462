import React from 'react';
import './Pasient.scss';

const Pasient = (props) => {
    const { gender, setGender, toNext } = props;

    const imageClick = (g) => {
        setGender(g);
        toNext();
    };

    return (
        <div className='pasient-question'>
            <h1>Pasient</h1>
            <p className='pasient-task'>Pasientens kjønn</p>
            <div className='answers-opt'>
                <div className={`answer-opts setwidth-${50 || 'standard'}`}>
                    <div className='img-wrapper'>
                        <button
                            onClick={() => imageClick('Mann')}
                            className={
                                gender === 'Mann' ? ' previously-selected' : ''
                            }
                        >
                            <img src={`images/mann-02.svg`} alt='' />
                        </button>
                    </div>
                    <h2>Mann</h2>
                </div>
                <div className={`answer-opts setwidth-${50 || 'standard'}`}>
                    <div className='img-wrapper'>
                        <button
                            onClick={() => imageClick('Kvinne')}
                            className={
                                gender === 'Kvinne'
                                    ? ' previously-selected'
                                    : ''
                            }
                        >
                            <img src={`images/kvinne.svg`} alt='' />
                        </button>
                    </div>
                    <h2>Kvinne</h2>
                </div>
            </div>
        </div>
    );
};

export default Pasient;
